<template>
  <div v-if="item">
    <b-img :src="bgHeader" class="header mb-2" />
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver" class="w-100 mr-lg-1">
      <b-form @submit.prevent="handleSubmit(processPayment)">
        <!-- Content -->
        <div class="d-flex justify-content-between">
          <a variant="link" class="d-flex align-items-center pb-2" @click="goBackSubscriptions">
            <feather-icon icon="ChevronLeftIcon" size="14" class="text-dark" />
            <h6 class="ml-50 text-dark m-0">
              {{ $t('onboarding.checkout.subscriptions') }}
            </h6>
          </a>
          <b-button
            class="mt-1 mb-2"
            type="submit"
            variant="primary"
          >
            <span v-if="item.total != null && item.total.toFixed(1) === '0.0'">
              {{ $t('onboarding.checkout.continue') }}
            </span>
            <span v-else>
              {{ $t('onboarding.checkout.continue') }}
            </span>
            <feather-icon icon="ChevronRightIcon" size="14" class="ml-50 mr-0 text-light" />
          </b-button>
        </div>

        <b-row class="justify-content-md-center">
          <!-- Sobre el producto -->
          <b-col cols="12" lg="6">
            <!-- ** ORDER ** -->
            <b-card no-body class="general-card">
              <b-card-body>
                <div class="p-2">
                  <!-- Product -->
                  <h4 class="mb-2">
                    1. {{ $t('onboarding.checkout.detail') }}
                  </h4>

                  <div v-if="item.total === null" class="w-100 justify-content-center d-flex my-2">
                    <b-spinner variant="primary" class="mx-auto" />
                  </div>
                  <text-resizable
                    :text-style="'text-primary text-center h5 mt-2 mb-1'"
                    :text="
                      item.total === 0 || item.total == null ? $t('onboarding.checkout.free') : parseFloat(item.total).toLocaleString(currentLocale, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 3
                      }) + item.currencySymbol
                    "
                    :default-font-size="3.5"
                    :default-text-length="12"
                    :font-size-to-rest="0.42"
                  />

                  <h2 class="mb-50 text-center title">
                    {{ translate(item.name) }}
                  </h2>
                  <p v-if="item.interval === 'month'" class="text-muted text-center">
                    <span v-if="item.intervalCount&&item.intervalCount>1">{{ $t('onboarding.checkout.months', {interval: item.intervalCount}) }}</span><span v-else>{{ $t('onboarding.checkout.month') }}</span>
                  </p>
                  <p v-if="item.interval === 'year'" class="text-muted text-center">
                    {{ $t('onboarding.checkout.year') }}
                  </p>
                  <p v-if="item.headline" class="mb-1 mt-2 text-center text-secondary">
                    {{ translate(item.headline) }}
                  </p>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
          <!-- Datos de Usuario -->
          <b-col cols="12" lg="6">
            <b-card class="general-card">
              <b-card-body>
                <div>
                  <!-- Logged User -->
                  <h4 class="mb-2">
                    2. {{ $t('onboarding.checkout.about-user') }}
                  </h4>
                  <b-row>
                    <b-col cols="6">
                      <b-form-group :label="$t('onboarding.checkout.form.name')" label-for="loggedUser name">
                        <b-form-input id="loggedUser name" :value="loggedUser.name" disabled />
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group :label="$t('onboarding.checkout.form.surname')" label-for="loggedUser surname">
                        <b-form-input id="loggedUser surname" :value="loggedUser.surname" disabled />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group :label="$t('onboarding.checkout.form.email')" label-for="loggedUser email">
                        <b-form-input id="loggedUser email" :value="loggedUser.email" disabled />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
          <!-- Metodos de pago -->
          <b-col cols="12">
            <b-card v-if="item.total !== 0">
              <b-card-body>
                <div class="p-2">
                  <h4 class="mb-2">
                    3. {{ $t('onboarding.checkout.form.payment') }}
                  </h4>
                  <b-form-group>
                    <b-form-checkbox-group
                      v-if="item.interval"
                      id="paymentOneTime"
                      v-model="paymentTypeOneTime"
                      class="mx-2 mt-1 d-flex"
                    >
                      <!-- stripe -->
                      <b-col
                        cols="6"
                        class="align-items-center justify-content-center d-flex"
                      >
                        <b-card
                          no-body
                          class="d-flex flex-column align-items-center justify-content-center px-50 py-75 mb-0 card-payment border-radius-8"
                          :border-variant="
                            paymentTypeOneTime === 'stripe'
                              ? 'primary'
                              : 'secondary'
                          "
                          for="stripe"
                          @click="paymentTypeOneTime = 'stripe'"
                        >
                          <b-form-checkbox
                            v-if="paymentTypeOneTime === 'stripe'"
                            id="stripe"
                            class="check-payment"
                            value="stripe"
                          />
                          <b-icon-credit-card
                            font-size="26"
                            class="text-center mb-25"
                            :class="
                              paymentTypeOneTime === 'stripe'
                                ? 'text-primary'
                                : 'text-secondary'
                            "
                          />
                          <span
                            class="font-small-2"
                            :class="
                              paymentTypeOneTime === 'stripe'
                                ? 'text-primary'
                                : 'text-secondary'
                            "
                          >{{
                            $t("onboarding.checkout.form.credit-card")
                          }}</span>
                        </b-card>
                      </b-col>
                    </b-form-checkbox-group>
                    <b-form-checkbox-group
                      v-else
                      id="payment"
                      v-model="paymentType"
                      required
                      class="mx-2 mt-1 d-flex"
                    >
                      <!-- Redsys -->
                      <b-col cols="6" class="align-items-center justify-content-center d-flex">
                        <b-card
                          no-body
                          class="d-flex flex-column align-items-center justify-content-center px-50 py-75 mb-0 card-payment border-radius-8"
                          :border-variant="paymentType === 'redsys' ? 'primary' : 'secondary'"
                          for="redsys"
                          @click="paymentType ='redsys'"
                        >
                          <b-form-checkbox
                            v-if="paymentType === 'redsys'"
                            id="redsys"
                            class="check-payment"
                            value="redsys"
                          />
                          <b-icon-credit-card
                            font-size="26"
                            class="text-center mb-25"
                            :class="paymentType === 'redsys' ? 'text-primary' : 'text-secondary'"
                          />
                          <span
                            class="font-small-2"
                            :class="paymentType === 'redsys' ? 'text-primary' : 'text-secondary'"
                          >{{ $t('onboarding.checkout.form.credit-card') }}
                          </span>
                        </b-card>
                      </b-col>
                    </b-form-checkbox-group>
                  </b-form-group>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
          <!-- Datos de Facturación -->
          <b-col cols="12">
            <div class="h-100 d-flex flex-column justify-content-center mb-2">
              <p class="mb-2 title">
                {{ $t('onboarding.checkout.need-bill') }}
              </p>
              <!-- Pregunta need bill -->
              <div class="d-flex">
                <p class="mr-50">
                  {{ $t('no') }}
                </p>
                <b-form-checkbox v-model="billNeeded" switch>
                  {{ $t('onboarding.checkout.yes') }}
                </b-form-checkbox>
              </div>
            </div>
          </b-col>
          <!-- Factura -->
          <b-col v-if="item && billNeeded" cols="12" lg="12">
            <b-card no-body class="general-card">
              <b-card-body>
                <div class="p-2">
                  <div class=" w-100 justify-content-between align-items-center">
                    <h4 class="mb-50">
                      <span v-if="item.total!==0">4.</span><span v-else>3.</span> {{ $t('onboarding.checkout.bill') }}
                    </h4>
                  </div>

                  <!-- Owner type -->
                  <div class="d-flex justify-content-around mt-1 mb-2 flex-wrap">
                    <a
                      class="owner-background d-block mx-auto mt-50"
                      :class="{ 'owner-background--active': isPerson }"
                      @click="getTypeOwner()"
                    >
                      <feather-icon
                        icon="UserIcon"
                        size="20"
                        class="d-block mx-auto"
                        :class="isPerson ? 'text-primary' : 'text-muted'"
                      />
                      <small class="mt-50" :class="isPerson ? 'text-primary' : 'text-muted'">
                        {{ $t('onboarding.checkout.individual') }}
                      </small>
                    </a>
                    <a
                      class="owner-background d-block mx-auto mt-50"
                      :class="{ 'owner-background--active': isOrganization }"
                      @click="getTypeOwner()"
                    >
                      <feather-icon
                        icon="BriefcaseIcon"
                        size="20"
                        class="d-block mx-auto"
                        :class="isOrganization ? 'text-primary' : 'text-muted'"
                      />
                      <small class="mt-50" :class="isOrganization ? 'text-primary' : 'text-muted'">
                        {{ $t('onboarding.checkout.business') }}
                      </small>
                    </a>
                  </div>

                  <!-- Data -->
                  <b-row>
                    <b-col cols="6">
                      <validation-provider #default="validationContext" name=" " rules="required">
                        <b-form-group :label="$t('onboarding.checkout.form.name')" label-for="name">
                          <b-form-input
                            id="name"
                            v-model="dataToSend.name"
                            required
                            type="text"
                            :disabled="disabled"
                            rules="required"
                            :placeholder="$t('onboarding.checkout.form.name-placeholder')"
                            :state="getValidationState(validationContext)"
                            aria-describedby="dataToSend-name"
                          />
                          <b-form-invalid-feedback id="dataToSend-name">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col cols="6">
                      <validation-provider #default="validationContext" name=" " rules="required">
                        <b-form-group :label="$t('onboarding.checkout.form.surname')" label-for="surname">
                          <b-form-input
                            id="surname"
                            v-model="dataToSend.surname"
                            type="text"
                            required
                            rules="required"
                            :disabled="disabled"
                            :placeholder="$t('onboarding.checkout.form.surname-placeholder')"
                            :state="getValidationState(validationContext)"
                            aria-describedby="dataToSend-surname"
                          />
                          <b-form-invalid-feedback id="dataToSend-surname">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col cols="12" :md="isOrganization?'12':'6'">
                      <validation-provider #default="validationContext" name=" " rules="required">
                        <b-form-group :label="$t('onboarding.checkout.form.email')" label-for="email">
                          <b-form-input
                            id="email"
                            v-model="dataToSend.email"
                            type="email"
                            required
                            rules="required"
                            :disabled="disabled"
                            :placeholder="$t('onboarding.checkout.form.email-placeholder')"
                            :state="getValidationState(validationContext)"
                            aria-describedby="dataToSend-email"
                          />
                          <b-form-invalid-feedback id="dataToSend-email">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      v-if="isOrganization"
                      cols="12"
                      sm="6"
                      lg="12"
                    >
                      <validation-provider #default="validationContext" name=" " rules="required">
                        <b-form-group :label="$t('onboarding.checkout.form.company')" label-for="company">
                          <b-form-input
                            id="company"
                            v-model="dataToSend.company"
                            type="text"
                            :disabled="disabled"
                            rules="required"
                            placeholder="Escribe aquí el nombre de la compañia..."
                            :state="isOrganization ? getValidationState(validationContext) : ''"
                            aria-describedby="dataToSend-company"
                          />
                          <b-form-invalid-feedback id="dataToSend-company" class="mb-0">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col v-if="!isOrganization" cols="12" md="6">
                      <validation-provider #default="validationContext" name=" " rules="required">
                        <b-form-group :label="$t('onboarding.checkout.form.dni')" label-for="dni">
                          <b-form-input
                            id="dni"
                            v-model="dataToSend.cif"
                            type="text"
                            :disabled="disabled"
                            rules="required"
                            :placeholder="$t('onboarding.checkout.form.dni-placeholder')"
                            :state="isOrganization ? getValidationState(validationContext) : ''"
                            aria-describedby="dataToSend-cif"
                          />
                          <b-form-invalid-feedback id="dataToSend-cif">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col v-if="isOrganization" cols="6">
                      <validation-provider #default="validationContext" name=" " rules="required">
                        <b-form-group :label="$t('onboarding.checkout.form.cif')" label-for="cif">
                          <b-form-input
                            id="cif"
                            v-model="dataToSend.cif"
                            type="text"
                            :disabled="disabled"
                            rules="required"
                            :placeholder="$t('onboarding.checkout.form.cif-placeholder')"
                            :state="isOrganization ? getValidationState(validationContext) : ''"
                            aria-describedby="dataToSend-cif"
                          />
                          <b-form-invalid-feedback id="dataToSend-cif">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="6"
                      sm="4"
                      :lg="isOrganization?'6':'3'"
                    >
                      <b-form-group :label="$t('onboarding.checkout.form.postal')" label-for="postal">
                        <b-form-input
                          id="postal"
                          v-model="dataToSend.postal"
                          type="text"
                          :disabled="disabled"
                          :placeholder="$t('onboarding.checkout.form.postal-placeholder')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      sm="8"
                      :lg="isOrganization?'12':'9'"
                    >
                      <b-form-group :label="$t('onboarding.checkout.form.address')" label-for="address">
                        <b-form-input
                          id="address"
                          v-model="dataToSend.address"
                          type="text"
                          :disabled="disabled"
                          :placeholder="$t('onboarding.checkout.form.address-placeholder')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      sm="12"
                      md="4"
                      lg="12"
                    >
                      <b-form-group :label="$t('onboarding.checkout.form.city')" label-for="city">
                        <b-form-input
                          id="city"
                          v-model="dataToSend.city"
                          type="text"
                          :disabled="disabled"
                          :placeholder="$t('onboarding.checkout.form.city-placeholder')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      sm="6"
                      md="4"
                      lg="6"
                    >
                      <b-form-group :label="$t('onboarding.checkout.form.province')" label-for="province">
                        <b-form-input
                          id="province"
                          v-model="dataToSend.province"
                          type="text"
                          :disabled="disabled"
                          :placeholder="$t('onboarding.checkout.form.province-placeholder')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      sm="6"
                      md="4"
                      lg="6"
                    >
                      <b-form-group :label="$t('onboarding.checkout.form.country')" label-for="country">
                        <b-form-input
                          id="country"
                          v-model="dataToSend.country"
                          type="text"
                          :disabled="disabled"
                          :placeholder="$t('onboarding.checkout.form.country-placeholder')"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <form
      id="redsysForm"
      :action="redSysURL"
      method="POST"
      style="visibility: hidden"
      target="_blank"
    >
      <input
        type="text"
        name="Ds_MerchantParameters"
        :value="redsysData.Ds_MerchantParameters"
        hidden
      >
      <input
        type="text"
        name="Ds_Signature"
        :value="redsysData.Ds_Signature"
        hidden
      >
      <input
        type="text"
        name="Ds_SignatureVersion"
        :value="redsysData.Ds_SignatureVersion"
        hidden
      >
      <input id="submitRedsys" type="submit">
    </form>
  </div>
</template>

<script>
import UserAvatar from '@core/components/user/UserAvatar.vue';
import { getImageResource } from '@/@core/utils/image-utils';
import CustomfieldsModal from '@/@core/components/modal/CustomfieldsModal.vue';
import ClassifiersModal from '@/@core/components/modal/ClassifiersModal.vue';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import Vue from 'vue';
import TextResizable from '@/backoffice/components/TextResizable.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import formValidation from '@core/comp-functions/forms/form-validation';
import { required } from '@validations';
import BgHeader from '@/assets/images/placeholders/light/banner-nectios.png';

export default {
  name: 'OrderPurchase',
  components: {
    UserAvatar,
    ClassifiersModal,
    CustomfieldsModal,
    TextResizable,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      isLoading: true,
      editorOption: { placeholder: 'Escriba su idea al reto' },
      methods: [],
      billNeeded: false,
      isPerson: true,
      isOrganization: false,
      isClassifiersModalVisible: false,
      isCustomFieldsModalVisible: false,
      newComment: '',
      nextStep: false,
      required,
      redsysData: {},
      disabled: false,
      disabledButtons: true,
      selectedAnswer: {},
      imageSrc: null,
      imageForum: null,
      paymentType: null,
      paymentTypeOneTime: null,
      forum: { name: '', description: '' },
    };
  },
  computed: {
    dataToSend() {
      if (this.loggedUser) {
        return {
          name: this.loggedUser.name,
          surname: this.loggedUser.surname,
          phone: '',
          city: '',
          company: '',
          cif: '',
          province: '',
          address: '',
          email: this.loggedUser.email,
          postal: '',
          country: '',
        };
      }
      return {
        name: '',
        surname: '',
        phone: '',
        city: '',
        company: '',
        cif: '',
        province: '',
        address: '',
        email: '',
        postal: '',
        country: '',
      };
    },
    id() {
      return this.$route.params.id;
    },
    bgHeader() {
      return BgHeader;
    },
    token() {
      return Vue.$cookies.get('accessToken');
    },
    type() {
      return this.$route.params.type;
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    item() {
      if (this.$store.getters.subscriptionPlans != null) {
        return this.$store.getters.subscriptionPlans.unpaginated[0];
      }
      return {};
    },
    redSysURL() {
      if (this.item.isTest) {
        return process.env.VUE_APP_PAY_REDSYS_TEST;
      }
      return process.env.VUE_APP_PAY_REDSYS;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    communitySlug() {
      return this.$route.params.community;
    },
    hasSubscription() {
      return !!this.$store.getters.communities?.toPay?.unpaginated?.[0]?.hasSubscription || false;
    },
  },
  setup() {
    const { getValidationState } = formValidation(() => {});
    return { getValidationState };
  },

  async created() {
    this.isLoading = true;
    await this.getCommunity();
    if (this.hasSubscription) {
      this.goToError();
      return;
    }
    await this.fetchData();
    document.getElementsByClassName('content-wrapper')[0].style.marginTop = '0px';
    if (this.item.interval == null) {
      this.paymentType = 'redsys';
    } else {
      this.paymentTypeOneTime = 'stripe';
    }
  },
  methods: {
    async handleInput(locale, localeValue) {
      this.$emit('input', { ...this.item, [locale]: localeValue });
    },
    onEnter() {
      this.$emit('input', [...this.info]);
    },
    validationRequirement(input) {
      return input.length > 0;
    },
    translate(field) {
      return translateTranslationTable(this.currentLocale, field);
    },
    goToError() {
      return this.$router.replace({
        name: 'my-error',
      });
    },
    async fetchData() {
      this.methods.push('subscription');
      this.methods.push('unique');
      await this.$store.dispatch('getItemsPayments', {
        itemType: 'subscriptionPlans',
        requestConfig: {
          key: this.id,
          subscriptionKey: this.id,
          isForNectios: true,
        },
      });
      this.isLoading = false;
    },
    async getCommunity() {
      await this.$store.dispatch('getItems', {
        itemType: 'communities/simply',
        storedKey: 'toPay',
        customName: 'communities',
        communitySlug: this.communitySlug,
      });
    },
    async processPayment() {
      const price = this.item.total;
      if (parseInt(price) !== 0) {
        let response;
        if (this.paymentTypeOneTime === 'stripe') {
          response = await this.callPaymentMethod('stripe');
        } else {
          this.paymentType === 'paypal'
            && (response = await this.callPaymentMethod('v1/paypal'));
          if (this.paymentType === 'redsys') {
            response = await this.callPaymentMethod('v1/redsys');
            this.redsysData = response;
            for (const row of Object.keys(response)) {
              document.getElementsByName(row)[0].value = response[row];
            }
            // document.getElementById("submitRedsys").click();
            document.getElementById('redsysForm').submit();
            return;
          }
        }
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        a.href = response;
        a.target = '_blank';
        a.click();
        document.body.removeChild(a);
      } else {
        await this.callPaymentMethod('subscriptions');

        window.location = `${process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace('{slug}', this.communitySlug).replace(
          '{subdomain}',
          'app',
        )}/backoffice`;
      }
    },
    async callPaymentMethod(itemType) {
      const response = await this.$store.dispatch('processPayment', {
        itemType,
        data: {
          ...this.dataToSend,
          priceKey: this.item.priceKey,
          communitySlug: this.communitySlug,
          locale: this.currentLocale,
        },
      });
      return response;
    },
    getTypeOwner() {
      this.isOrganization = !this.isOrganization;
      this.isPerson = !this.isPerson;
    },

    goBackSubscriptions() {
      return this.$router.replace({
        name: 'my-plans',
        params: { community: this.communitySlug },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.price {
  font-size: 3.2rem;
}
.general-card {
  min-height: 24.8rem;
}
.card-payment {
  width: 106px;
  height: 69px;
}
.check-payment {
  position: absolute;
  top: -6px;
  left: -5px;
}
.header {
  object-fit: cover;
  background-color: rgb(255, 255, 255);
  width: 100%;
  border-radius: 1.2rem;
}
</style>
